import { getConfig } from './conf'
import { type Config } from '/@/core/config'

import('./App/create').then(async ({ createApp }) => {
  const config = getConfig()
  const { app, router } = await createApp(config as Config)

  router.isReady().then(() => {
    app.mount('#app')
  })
})
